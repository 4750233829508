/* @font-face {
  font-family: Cerebri sans;
  src: url(./fontfiles/Cerebri\ Sans\ Regular.otf);
} */

@font-face {
  font-family: Cerebri sans;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(./fontfiles/Cerebri_Sans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Cerebri sans-light;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(./fontfiles/Cerebri_Sans-Light.ttf) format("truetype");
}

@font-face {
  font-family: Cerebri sans-medium;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(./fontfiles/Cerebri_Sans-Medium.ttf) format("truetype");
}

@font-face {
  font-family: Cerebri sans-semibold;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(./fontfiles/Cerebri_Sans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: Cerebri sans-bold;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(./fontfiles/Cerebri_Sans-Bold.ttf) format("truetype");
}

/* @font-face {
  font-family: Cerebri sans-extrabold;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(./files/Cerebri_Sans-ExtraBold.otf);
}

@font-face {
  font-family: Cerebri sans-heavy;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(./files/Cerebri_Sans-Heavy.otf);
} */

.regular {
  font-family: Cerebri sans !important;
}

.Cerebri-sans-light {
  font-family: Cerebri sans-light;
}

.Cerebri-sans-bold {
  font-family: Cerebri sans-bold;
}
.Cerebri-sans-semibold {
  font-family: Cerebri sans-semibold;
}

.Cerebri-sans-medium {
  font-family: Cerebri sans-medium;
}

/* fontweight */

/* .fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
} */

/* pointer */

.cursor {
  cursor: pointer;
}

/*  */

/* fontSize */

/* fs-1 -- 40px
fs-2 -- 32px
fs-3 -- 28px, 
fs-4 -- 24px ,25px ,26px


fs-5 -- 20px


fs-6 -- 16px  */

/* fs-7 -- 14px */

.fs-7 {
  font-size: 0.875rem !important;
}

/* fs-8 -- 12px */

.fs-8 {
  font-size: 0.75rem !important;
}

/* fs-9 -- 10px */

.fs-9 {
  font-size: 0.625rem !important;
}

.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}

.fs-16 {
  font-size: 16px;
}
.fs-14 {
  font-size: 14px;
}
.fs-13 {
  font-size: 13px;
}

.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px;
}
.fs-28 {
  font-size: 28px;
}
.fs-48 {
  font-size: 48px;
}

/* color  */

.hr {
  color: rgba(240, 240, 240, 1);
}

.color-white {
  color: #ffffff !important;
}

.border {
  border: 1px solid rgba(240, 240, 240, 1);
}

.bg-grey {
  background-color: rgba(240, 240, 240, 1);
}

.grey-text {
  color: #888888 !important;
}
.gray {
  color: #999 !important;
}

.green-text {
  color: #619130 !important;
}
.screening-navy-text {
  color: #111747 !important;
}
.screening-blue-text {
  color: #2a39af !important;
}
.screening-green-text {
  color: #91aa7b;
}

.nowrap {
  white-space: nowrap;
}

/* Radius */

.radius-4 {
  border-radius: 4px;
}

.radius-6 {
  border-radius: 6px !important;
}

.radius-8 {
  border-radius: 8px;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-12 {
  border-radius: 12px !important;
}

/* recent activity card variables */
.msg-line {
  background-color: #f0f0f0;
}

/* recent activity card variables */

/* background */

.bg-red {
  background-color: #d00200 !important;
}

.bg-orange {
  background-color: #ff8a1f !important;
}
.bg-estateOrange {
  background-color: #f1664b !important;
}

.bg-green {
  background-color: #adbe9e !important;
}

.bg-blue {
  background-color: #97bfb5 !important;
}

.bg-light-green {
  background-color: #74b85d !important;
}

.bg-grey {
  background-color: #d1d1d1 !important;
}

.background-primary {
  background-color: #f7f8fa !important;
}

.bg-login {
  background-color: #e0e0e0;
  min-height: 100vh;
}

.bg-slider {
  background-color: #0c1356 !important;
}

.bg-primary {
  background-color: #0c1356 !important;
}

.bg-white {
  color: white;
}

.bg-incoming-color {
  background-color: #2a38af;
}

.bg-event-color {
  background-color: #00a3ff;
}
.bg-private-view-color {
  background-color: #ff9900;
}

/* color */

.bg-text-color {
  color: #adbe9e;
}

.color-white {
  color: #fff;
}

.color-black {
  color: #000000;
}

.color-gray {
  color: rgba(0, 0, 0, 0.5);
}

.dropdown-secondary-color {
  color: #898989 !important;
}

.primary-text-color {
  color: #111747 !important;
}

.incoming-text-color {
  color: #2a39af !important;
}

.cards-primary-text-color {
  color: #202020;
}

.cards-secondary-text-color {
  color: #999999 !important;
}

.document-card-secondary-text {
  color: #aa836c !important;
}

.text-grey {
  color: #888888;
}

.text-black {
  color: rgba(0, 0, 0, 0.5);
  opacity: 50%;
}

.bg-button-success {
  background-color: #adbe9e;
}

.button-primary-text {
  color: #adbe9e;
}

/* custom scroller */

.scrollable-container-x {
  overflow-x: auto !important;
  overflow-y: hidden !important;
  margin-bottom: 6px !important;
}

.scrollable-container-x::-webkit-scrollbar {
  height: 5px;
  border-radius: 100px;
  overflow: visible;
  width: 5px;
}

.scrollable-container-x::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 100px;
}

.scrollable-container-x::-webkit-scrollbar-track {
  background-clip: padding-box;
}

.scrollable-container-y {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
  margin-right: 6px !important;
}

.scrollable-container-y::-webkit-scrollbar {
  width: 5px;
  border-radius: 100px;
  overflow: visible;
}

.scrollable-container-y::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 100px;
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
}

.scrollable-container-y::-webkit-scrollbar-track {
  background-clip: padding-box;
}

::placeholder {
  color: #000000 !important;
  font-family: Cerebri sans !important;
}

.copy-link {
  color: #3745b3;
}

.screening-header {
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #f7f8fa;
  border-radius: 12px;
}
