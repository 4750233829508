body {
  font-family: Cerebri sans !important;
}

/* Font variants should not be used anyhwere */
/* .font-light {
  font-family: Cerebri sans-light !important;
}

.font-medium {
  font-family: Cerebri sans-medium !important;
}

.font-semibold {
  font-family: Cerebri sans-semibold !important;
}

.font-bold {
  font-family: Cerebri sans-bold !important;
} */

/* Common classes */

.created-by {
  font-family: Cerebri sans-light !important;
  color: #888888;
  font-size: 12px;
}

.copyright {
  font-family: Cerebri sans-light !important;
  color: #111747;
  font-size: 12px;
}

.bold-fonts {
  font-family: Cerebri sans-semibold !important;
  font-size: 36px;
  color: #111747;
}

.input-headings {
  font-family: Cerebri sans-bold !important;
  font-size: 16px;
  color: #111747;
}

.header-main-title {
  font-family: Cerebri sans-semibold !important;
  font-size: 24px;
}

/* // CONATINER HEADINGS FONTS // */

.container-title {
  font-family: Cerebri sans-bold;
  font-size: 14px;
}

.container-title-18 {
  font-family: Cerebri sans-bold;
  font-size: 18px;
}

.container-title-20 {
  font-family: Cerebri sans-bold;
  font-size: 20px;
}

/* // NORMAL FONTS // */

.normal-font-text {
  font-family: Cerebri sans;
}

/* // Medium Fonts //  */

.medium-text {
  font-family: Cerebri sans-medium !important;
  font-size: 16px;
}

.medium-text-12 {
  font-family: Cerebri sans-medium !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.medium-text-14 {
  font-family: Cerebri sans-medium !important;
  font-size: 14px;
  font-weight: 500;
}
.light-text-14 {
  font-family: Cerebri sans-light !important;
  font-size: 14px;
}

.medium-text-18 {
  font-family: Cerebri sans-medium;
  font-size: 18px;
}

/* // SEMI-BOLD FONTS //  */

.semi-text-16 {
  font-family: Cerebri sans-bold;
  font-size: 16px;
}
.semibold-text-16 {
  font-family: Cerebri sans-semibold;
  font-size: 16px;
}

/* // LIGHT FONTS //  */

.light-font-text {
  font-family: Cerebri sans-light !important;
  font-size: 14px !important;
}

.login-form-heading {
  font-family: Cerebri sans-bold;
  font-size: 36px;
}

/* // ERROR MSG FONTS // */

.error-msg {
  font-size: 12px;
  color: red;
  font-family: Cerebri sans-light;
  /* padding-left: 15px; */
  padding-top: 4px;
  margin-bottom: 0px;
}

.table-property-name {
  font-family: Cerebri sans-medium;
  font-size: 13px;
}

.table-grey-text {
  font-family: Cerebri sans-light;
  font-size: 11px;
  color: #888888;
}

.form-label-title {
  font-family: Cerebri sans-medium;
  font-size: 14px;
}

.standard-input {
  border: none;
  outline: none; /* Remove the outline when focused */
  background-color: transparent;
  width: 100%;
}

.standard-input:hover {
  border: none;
}

/* Actors css */
.actors-section-title {
  font-family: Cerebri sans-medium;
  font-size: 13px;
}

.page-item:first-child .page-link {
  border-radius: 8px !important;
  height: 37px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #f4f5f5 !important;
}
.page-item:last-child .page-link {
  border-radius: 8px !important;
  height: 37px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #f4f5f5 !important;
}

.page-item:not(:first-child) .page-link {
  border-radius: 8px;
  height: 37px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #f4f5f5 !important;
}

/* .active > .page-link,
.page-link.active {
  background-color: #fff;
  font-weight: 500;
  color: #000;
  border-color: #fff;
} */
.active > .page-link,
.page-link.active {
  background-color: #fff !important;
  font-weight: 600 !important;
  color: #000 !important;
  border-color: #f4f5f5 !important;
}

.form-select {
  font-size: 13px !important;
}

/* ----- styled elements for para ----- */

.paragraph1-light {
  font-size: 16px;
  font-family: Cerebri sans-light;
  line-height: 22px;
  letter-spacing: 0;
  color: #202020;
}
.paragraph1-regular {
  font-size: 16px;
  font-family: Cerebri sans;
  line-height: 22px;
  letter-spacing: 0;
  color: #202020;
}
.paragraph2-light {
  font-size: 14px;
  font-family: Cerebri sans-light;
  line-height: 20px;
  letter-spacing: 0;
  color: #202020;
}
.paragraph2-regular {
  font-size: 14px;
  font-family: Cerebri sans;
  line-height: 20px;
  letter-spacing: 0;
  color: #202020;
}
.caption-light {
  font-size: 12px;
  font-family: Cerebri sans-light;
  line-height: 14px;
  letter-spacing: 0;
  color: #1a1a1a;
}
.caption-regular {
  font-size: 12px;
  font-family: Cerebri sans;
  line-height: 14px;
  letter-spacing: 0;
  color: #1a1a1a;
}
.caption-medium {
  font-size: 12px;
  font-family: Cerebri sans-medium;
  line-height: 14px;
  letter-spacing: 0;
  color: #1a1a1a;
}
.label-regular {
  font-size: 12px;
  font-family: Cerebri sans;
  line-height: 14px;
  letter-spacing: 0;
  color: #202020;
}
.label-medium {
  font-size: 12px;
  font-family: Cerebri sans-medium;
  line-height: 14px;
  letter-spacing: 0;
  color: #202020;
}
