.property-details div span:nth-child(1) {
  width: 50%;
}

.finish-screen-btn {
  background-color: #74B85D !important;
  border: none !important;
}

.lead-home-guy {
  top: -195px;
  right: 20px;
}


@media only screen and (min-width: 1200px) {
  .lead-description :last-child {
    margin-right: 45% !important;
  }
}