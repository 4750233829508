.profile-header {
  justify-content: space-between;
  width: 100%;
  background-color: #DAD4CE;
  border-radius: 9px;
  /* background-image:url(../../../assets/images/Profile\ Pic.png); */
}

.profile-div {
  /* background-image: url(../assets/images/Profile\ Pic.png); */
  height: 152px;
  width: 152px;
  border-radius: 100%;

}

.remove-profile-div {
  height: 152px;
  width: 152px;
  border-radius: 100%;


}

.bg-orange {
  background-color: #ff8a1f !important;
}

.form-div {
  background-color: #FFFFFF;
  /* height: 1299px; */
  border-radius: 8px;
}

.gray-bg {
  background: #F7F8FA;
  border-radius: 8px;

}

.profile-info {
  line-height: 22px;
  color: #202020;
}

.profile-title {
  font-family: 'Cerebri Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #999999;
}

.profile-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #2A39AF;

}

.disable-profile-text {
  font-size: 18px;
  line-height: 22px;
  color: #111747;
}

.font-style {
  font-family: 'Cerebri Sans';
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #202020;
}

.fn-16 {
  font-size: 16px;
}

.fn-14 {
  font-size: 14px;
}

.fs-17 {
  font-size: 17px;
}

.fs-26 {
  font-size: 26px;
}

/* input tag */



.label-up {
  top: 0 !important;
  font-size: 10px !important;
  color: #888;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(247, 247, 247, 0) 50%);
  font-size: 8px !important;

  padding: 4px;
}

/* .form-input:focus-within label,
.form-input:focus-within :not(:placeholder-shown) label {
  top: 0;
  color: #888;
  background: #F9FAFB;
  padding: 4px;
} */

/* option */
/* .form-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 300;
}

.form-input option::placeholder {
  opacity: 0;

}

.form-input option {
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 400;
  height: 40px; */
/* font-size: 10px !important; */
/* padding: 10px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;

} */

/* .form-input label {
  font-family: Cerebri sans !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
  letter-spacing: 1px;
  transition: .1s;
  font-weight: 300 !important;
}

.form-input option:focus+label,
.form-input input:not(:placeholder-shown)+label {
  top: 0;
  color: #888;
  background: #F9FAFB;
  padding: 4px;
  font-size: 10px;
}

.form-input option:focus {
  border: 1px solid #888;
} */

/* .form-input input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
} */

/* .label-up {
  top: 0 !important; */
/* font-size: 10px !important; */
/* color: #888;
  background: #F9FAFB;
  padding: 4px;
  font-size: 10px;
} */

/* .form-input:focus-within label,
.form-input:focus-within :not(:placeholder-shown) label {
  top: 0;
  color: #888;
  background: #F9FAFB;
  padding: 4px;
} */






/* for switch button */

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F0F0F0;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;

  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:after {

  right: 2px;

}

input:checked+.slider {
  background-color: #74B85D;
}

input:focus+.slider {
  box-shadow: 0 0 1px #F0F0F0;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.notifaction-text {
  font-family: 'Cerebri Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #999999;
}

.input-error {
  color: red;
  font-size: 14px;
  font-family: 'Cerebri Sans';
  font-style: normal;
}

/* optionbtn */

.wrap {
  position: absolute;
  right: 0;
  top: 40%;
  width: 100%;
  left: 0;
  margin: 0 auto;
}

/* select starting stylings ------------------------------*/
.select {
  font-family:
    'Roboto', 'Helvetica', 'Arial', sans-serif;
  position: relative;
  width: 100%;
  border: 1px solid #D1D1D1
}

.select-text {
  position: relative;
  font-family: inherit;
  background-color: transparent;
  width: 350px;
  padding: 10px 10px 2px 10px;
  font-size: 18px;
  border-radius: 0;
  border: none;

}

/* Remove focus */
.select-text:focus {
  outline: none;
}

/* Use custom arrow */
.select .select-text {
  appearance: none;
  -webkit-appearance: none
}

.select:after {
  position: absolute;
  top: 18px;
  right: 10px;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  pointer-events: none;
}

/* LABEL ======================================= */
.select-label {
  color: black;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
}

/* active state */
.select-text:focus~.select-label,
.select-text:valid~.select-label {
  color: gray;
  top: -4px;
  transition: 0.2s ease all;
  font-size: 14px;
  background: #f7f8fa;
}

/* BOTTOM BARS ================================= */
.select-bar {
  position: relative;
  display: block;
  width: 350px;
}

.select-bar:before,
.select-bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  /* background: #2F80ED; */
  transition: 0.2s ease all;
}

.select-bar:before {
  left: 50%;
}

.select-bar:after {
  right: 50%;
}

/* active state */
.select-text:focus~.select-bar:before,
.select-text:focus~.select-bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.select-highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

.phone-input-filed {
  width: 200% !important;
  font-family: Cerebri sans !important;
  font-weight: 400;
  height: 40px;
  /* font-size: 10px !important; */
  padding: 10px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
}

.phone-read-only {

  /* width: 100%; */
  font-family: Cerebri sans !important;
  font-weight: 400;
  height: 40px;
  /* font-size: 10px !important; */
  padding: 10px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px 0px 0px 8px;
}


/* form */

.form-inpu-field {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  font-weight: 300;
}

.form-inpu-field input::placeholder {
  opacity: 0;

}

.form-inpu-field input {
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  font-weight: 400;
  height: 40px;
  /* font-size: 10px !important; */
  padding: 10px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 0px 8px 8px 0px !important;

}

.form-inpu-field label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 61px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000000;
  letter-spacing: 0.25px !important;
  transition: 0.1s;
  /* font-weight: 300 !important; */
  padding: 0px 4px 0px 4px !important;
}

.form-inpu-field input:focus+label,
.form-inpu-field input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 8px !important;
  letter-spacing: 0.25px !important;
  font-family: Cerebri sans !important;
  color: #000000;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(247, 247, 247, 0) 50%);
  padding: 4px;
}

.form-inpu-field input:focus {
  border: 1px solid #888;
}

.form-inpu-field input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

/* .label-up {
  top: 0 !important;
  font-size: 10px !important;
  color: #888;
  font-size: 8px !important;

  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(247, 247, 247, 0) 50%);

  padding: 4px;
} */



.pointer-none {
  pointer-events: none;
}

/* 
.form-inpu-field-readonly:focus-within :not(:placeholder-shown) label {
  top: 0;
  color: #888;
  background: #F9FAFB;
  padding: 4px;
} */

/* notifaction  */
.bg-light-blue {
  background: #CED2EB;
  border-radius: 6px;
}

.bg-light-gray {
  background: #EBEBEB;
  border-radius: 6px;
}

.profile-edit {
  right: 11px;
  bottom: 3px;
  border: 2px solid #DAD4CE;
  border-radius: 100px;
  background-color: white;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-fname-lname {
  font-weight: 500;
  font-size: 100px;
  line-height: 125px;
  color: #FFFFFF;

}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: white;
  border: 1px solid #ced4da;

}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: white !important;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}


.form-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 300;
}

.form-input select::placeholder {
  opacity: 0;

}

.form-input select {
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 400;
  height: 40px;
  /* font-size: 10px !important; */
  padding: 10px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;

}

/* .form-input label {
  font-family: Cerebri sans !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
  letter-spacing: 1px;
  transition: .1s;
  font-weight: 300 !important;
} */

.form-input select:focus+label,
.form-input select:not(:placeholder-shown)+label {
  top: 0;
  color: #888;
  background: #F9FAFB;
  padding: 4px;
  font-size: 10px;
}

.form-input select:focus {
  border: 1px solid #888;
}

.form-input select:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

/* OTP modal */

.profile-otp-container {
  display: flex;
  justify-content: flex-start;
}

.profile-otp-input {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 2px;
  border: none;
  font-size: 24px;
  text-align: center;
  outline: none;
}

.profile-otp-input:focus {
  background-color: #E3E5F8;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.profile-otp-container .otp-border {
  width: 100%;
  background-color: #D1D1D1;
  height: 4px;
  border-radius: 10px;
}

.profile-otp-input:focus + div {
  background-color: #2A39AF;
}

.profile-otp-input:focus {
  border-color: #0c1356;
}

/* Optional: To add more styling for completed inputs */
.profile-otp-input.completed {
  border-color: #28a745;
}