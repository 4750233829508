.process-timeline .process-item {
  position: relative;
  z-index: 10;
}

.process-item .process-icon {
  margin-top: 2rem;
}

.recent-timeline-line::before {
    background: #F0F0F0;
    content: "";
    height: calc(100% - -4rem);
    left: 38px;
    position: absolute;
    top: -1rem;
    width: 1px;
    z-index: -1;
}
