.delete-document-box {
  background: #fff;
  border: 1px solid #D1D1D1;
  border-radius: 6px;
}

.share-btn {
  background: #ffffff;
  border: 1px solid rgba(17, 23, 71, 0.1);
  border-radius: 6px;
}

.del-btn-text {
  font-family: Cerebri sans-semibold;

  font-size: 28px;
  line-height: 35px;
  text-align: center;
  color: #202020;
}

.del-text {
  /* font-weight: 400;
  font-size: 16px;
  line-height: 20px; */
  /* identical to box height */

  text-align: center;

  color: rgba(32, 32, 32, 0.75);
}

.list-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #202020;
}

.all-archive-text {
  font-family: Cerebri sans-medium !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #111747;
}

.all-archive-graytext {
  font-family: Cerebri sans-light !important;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  /* My Page/NAVY */

  color: #111747;

  opacity: 0.5;
}

.hader-title {
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #111747;
}

.font-style {
  font-family: "Cerebri Sans";
}

/* .MuiBox-root.css-di3982 {
    padding-right: 3rem;
    padding-left: 0.5rem;
    background-color: white;
    margin-left: 1rem;
    border-radius: 6px;
    height: 38px !important;
 
} */

.contained-btn:hover {
  background: white;
  box-shadow: none;
  border-radius: 6px;
}

.contained-btn {
  background: white !important;
  box-shadow: none;
  color: black;
  border-radius: 6px;
}

.contained-download {
  background: #d2dbcc !important;
  color: white;
  border-radius: 6px;
  /* opacity: 50%; */
}

.contained-download-text {
  background: #d2dbcc;
  color: white !important;
  border-radius: 6px;
}

.contained-download-text:hover {
  background: #d2dbcc !important;
  color: white !important;
  border-radius: 6px;
}

.added-document-delete-btn {
  background: white;
  border: 1px solid #d1d1d1;
  padding: 0.3rem 0.5rem 0.2rem;
  border-radius: 4px;
}

/* documents share popup */

.document-copy-link-field {
  width: 100%;
  height: 40px;
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  /* overflow: hidden; */
}

.form-input.document-copy-link-field input {
  border: none !important;
  outline: none !important;
  height: 100% !important;
  background-color: transparent !important;
  white-space: nowrap;
  width: 80% !important;
  text-overflow: ellipsis;
}

.form-input.document-copy-link-field input:focus,
.form-input.document-copy-link-field input:hover {
  box-shadow: none !important;
}

  .douments-share-copy-link {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .document-copy-link-field-container {
    max-height: 140px;
    overflow: auto;
  }
