.progress {
    height: 5px !important;
}
.progress-bar {
    background-color: #77C970 !important;
}

.occupation-dropdown .dropdown-toggle {
    position: relative;
    font-size: 0.8rem;
    color: black !important;
    padding-right: 4rem !important;
    background-color: white;
}

.occupation-dropdown .dropdown-toggle::after {
    border: none !important;
    display: none;
}

.occupation-btn-group input:focus {
    border: none;
    outline: none
}

.occupation-btn-group input[type="radio"]:checked+label {
    background-color: #E9F8E8;
    border: 1px solid #54A64D !important;
}

.occupation-btn-group label {
    background-color: white;
}

.selected-field {
    background-color: #E9F8E8 !important;
    border: 1px solid #54A64D !important;
}

.reservation-form .dropdown-toggle[aria-expanded="true"] svg {
    transform: rotate(180deg) !important;
  }

.reservation-container {
    border-radius: 12px;
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.06);
    overflow-x: hidden;
}
.reservation-container input:focus {
    /* border: none !important; */
    outline: none !important;
}

.screening-accordion-body .commented-container {
    border-radius: 6px;
    border: 1px solid #D1D1D1;
    background: #F9FAFA;
    height: 100px;
    overflow: auto;
}

.screening-accordion-body .commented-container .comment-delete-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 20px;
    bottom: 10px;
    border-radius: 4px;
    border: 1px solid #D1D1D1;
    background: #FFF;
    width: 30px;
    height: 30px;
}

.screening-accordion-body textarea {
    resize: none;
    width: 100%;
    height: 100%;
    font-size: 14px;
    padding: 12px;
    outline: none;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
}

.screening-accordion-body .file-select {
    border: 1.8px dashed #D1D1D1 !important;
    border-radius: 8px;
}

.screening-accordion-body .file-select .browse-btn{
    background-color: white;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
}