.lead-loader-color {
  background-color: #77c970;
}
.lead-checkbox-color {
  color: #54a64d;
}

.card-box {
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bottom-border-line {
  border-bottom: 1px solid #f0f0f0;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  accent-color: #2a39af !important;
}

.color-black {
  color: #000;
}

.sidebar-tabs-color {
  color: #8589aa;
}

/* .modal-content{
  width: 120% !important;
} */

