.week {
    background: rgba(241, 102, 75, 0.5);
    color: black;
    border: 1px solid #F1664B;
    border-radius: 4px;
    padding: 3.7px;
    font-size: 14px;
    text-align: center;
}

.fair {
    background: rgba(246, 230, 81, 0.5);
    border: 1px solid #F6E651;
    border-radius: 4px;
    color: black;
    padding: 3.7px;
    font-size: 14px;

    text-align: center;
}

.good {
    background: rgba(119, 199, 161, 0.5);
    border: 1px solid #77C7A1;
    border-radius: 4px;
    color: black;
    padding: 3.7px;
    font-size: 14px;

    text-align: center;
}

input::placeholder {
    opacity: 0.5;
    color: rgba(17, 23, 71, 0.5);

}

.input-value-color {
    opacity: 0.9;
    color: rgba(17, 23, 71, 0.5);
}

.weakline {
    margin-top: 10px;
    height: 5px;
    border-radius: 5px;
    background-color: #F6E651;
    width: 30%;
}

.fairline {
    margin-top: 10px;
    height: 5px;
    border-radius: 5px;
    background-color: #F7A61E;
    width: 70%;
}

.goodline {
    margin-top: 10px;
    height: 5px;
    border-radius: 5px;
    background-color: #54A64D;
    width: 100%;
}