.dropup .dropdown-toggle {
    position: relative;
    font-size: 0.8rem;
    color: black !important;
    padding-right: 4rem !important;
}

.dropup .dropdown-toggle::after {
    border: none !important;
}

.dropdown-caret {
    position: absolute;
    right: 15px;
    top: 50%;
    font-size: 1.3rem;
    transform: translateY(-50%);
}

.dropdown-item:active {
    background-color: transparent !important;
    color: inherit !important;
}

.log-user li:hover{
    background-color: #f8fcff;
}

@media screen and (min-width: 768px){

    .bg-slider {
        width: 250px !important;
    }
    .my-portal-header {
        padding-left: 265px;
    }

    .dashboard-container {
        padding-left: 270px;
        padding-top: 122px;
    }
}

@media screen and (min-width: 1400px){
    .dashboard-container {
        padding-top: 150px;
    }
}
@media screen and (width: 320px){
    .profile-image {
        display: none;
        padding: 10px;
        /* height: 325px; */
    }
}
/* @media screen and (width: 768px) {
    .profile-image {
        display: none;
        
    }
} */
/* @media screen and (width: 375px) {
    .profile-image {
        display: none;
       
    }
} */
/* @media screen and (width: 375px) {
    .profile-image {
        display: none;
        
    }
} */
@media screen and (max-width: 1091px) {
    .profile-image {
        display: none;
        /* padding: 10px; */
        /* height: 325px; */
    }
}


/* media queries */

@media screen and (max-width: 768px) {
    .dashboard-container {
        padding-top: 90px;
    }
}