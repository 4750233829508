 .nav-bar.active li{
    background-color: #242b67;
    color: white !important;
  }

  .sidebar.active .sidebar-svg  {
    background-color: #ffffff;
  }

  .sidebar .sidebar-svg{
    background-color: #85898a;
  }