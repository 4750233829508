body {
  width: 100%;
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  font-family: Cerebri sans !important;
  background-color: #f7f8fa !important;
}

::placeholder {
  color: rgba(17, 23, 71, 0.5);
}

.row {
  margin: 0 !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

li {
  list-style: none;
}

button {
  white-space: nowrap !important;
}

.modal-backdrop {
  background-color: #111747 !important;
}

.css-68rqdf {
  gap: 10px !important;
}

.css-18c75ho-MuiButtonBase-root-MuiTableSortLabel-root {
  color: #c7c7c7 !important;
}

.css-68rqdf {
  gap: 10px !important;
}

.css-18c75ho-MuiButtonBase-root-MuiTableSortLabel-root {
  color: #c7c7c7 !important;
}

/* .css-vtos91-placeholder {
  display: none !important;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

::-ms-reveal {
  display: none;
}

@media screen and (max-width: 576px) and (min-width: 320px) {
  .testclass {
    width: 100% !important;
  }
}

/* .accordion-button::after {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="0.875em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>') !important;
  height: 17px !important;
}
.accordion-button:not(.collapsed)::before {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>') !important;
  height: 17px !important;
} */

/* @media screen and (min-width: 992px) {
  .css-sq9qdz{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
} */
