.events-container {
  background-color: #f1f5f0;
  border-radius: 8px;
}

.events-body-container {
  background-color: #ffffff;
  border-radius: 8px;
}

.events-headers-border {
  border-bottom: 1px solid #f0f0f0;
}

.open-house-container {
  background-color: #f1f4ef;
}

.open-house-headers {
  background-color: #ffffff;
}

.open-house-body {
  background-color: #ffffff;
  border-radius: 8px 8px 0px 0px;
}

textarea:focus ~ .floating-label,
textarea:not(:placeholder-shown) ~ .floating-label,
input:not(:focus):valid ~ .floating-label {
  top: 0px;
  left: 10px;
  transform: translateY(-50%);
  transition: 0.1s;
  padding: 4px;
  letter-spacing: 0.25px !important;
  font-family: Cerebri sans-medium !important;
  font-size: 8px;
  opacity: 1;
  background-color: white;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(247, 247, 247, 0) 50%
  );
  color: #000 !important;
}

/*---------openhouse-des---------*/
.openhouse-des textarea {
  width: 100% !important;
  padding: 10px 10px 0 13px;
  color: #000000;
  /* font-weight: 300; */
  letter-spacing: 0.25px !important;
  border-radius: 8px !important;
  border: 1px solid #d1d1d1 !important;

  font-size: 14px;
  font-family: Cerebri sans-light !important;
  box-sizing: border-box;
}

.openhouse-des-error textarea {
  width: 100% !important;
  padding: 10px 10px 0 13px;
  color: #000;
  /* font-weight: 300; */
  letter-spacing: 0.25px !important;
  border-radius: 8px !important;
  border: 1px solid #e33716 !important;

  font-size: 14px;
  font-family: Cerebri sans-light !important;
  box-sizing: border-box;
}

/*---------form-float---------*/

.form-float {
  position: relative;
  font-family: Cerebri sans-light !important;
}

.form-float textarea::placeholder {
  opacity: 0;
}

.form-float label {
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;

  transition: 0.1s;
  letter-spacing: 0.25px;
  color: #000;
  font-size: 14px;
  font-family: Cerebri sans-light !important;
  padding: 0px 4px 0px 4px !important;
}

.form-float textarea:focus + label,
.form-float textarea:not(:placeholder-shown) + label {
  top: 0;
  font-size: 8px !important;
  letter-spacing: 0.25px;
  font-family: Cerebri sans-medium !important;
  color: #000 !important;
  background: rgb(255, 255, 255);

  font-weight: 500;
  padding: 4px;
  /* font-weight: 300;  SV-20230516 - not needed as per figma */
}

.form-input textarea:disabled + label {
  background-color: #fff;
  color: #888 !important;
  font-family: Cerebri sans-medium !important;
  /* opacity: 0.6; */
  cursor: not-allowed;
}

.form-float textarea:focus-visible {
  outline: none !important;
  border: 0.5px solid #000000 !important;
}

.form-float textarea:focus {
  border: 0.5px solid #000000 !important;
}

.form-float textarea:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.form-float input:-webkit-autofill,
.form-float input:-webkit-autofill:hover,
.form-float input:-webkit-autofill:focus,
.form-float input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

/*---------form-float-error---------*/
.form-float-error {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.form-float-error label {
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transform: translateY(-50%);
  transition: 0.1s;
  letter-spacing: 0.25px;
  color: #000;

  font-size: 14px !important;
  font-family: Cerebri sans-light !important;
}

.form-float-error textarea:focus {
  border: 1px solid #e33716 !important;

  letter-spacing: 0.25px;
}

.form-float-error textarea:hover {
  border: 1px solid #e33716 !important;
}

.form-float-error textarea::placeholder {
  opacity: 0;
  border: none !important;

  /* color: #000 !important; */
}

.form-float-error textarea:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.form-float-error textarea:focus + label,
.form-float-error textarea:not(:placeholder-shown) + label {
  top: 0;
  font-size: 8px !important;
  letter-spacing: 0.25px;
  font-family: Cerebri sans-medium !important;
  color: #888 !important;
  background: #fff;
  /* background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(247, 247, 247, 0) 50%
  ); */
}

.form-float-error input:-webkit-autofill,
.form-float-error input:-webkit-autofill:hover,
.form-float-error input:-webkit-autofill:focus,
.form-float-error input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

.openHouseinput input::placeholder {
  padding-left: 16px !important;
}

/* /////////////////////////// Calendar CSS ///////////////////// */

.rbc-btn-group button {
  padding: 30px;
}

.rbc-header {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.my-main-event-calendar .rbc-month-view {
  background-color: #f9fafa;
  border-radius: 8px;
}

/* .my-main-event-calendar .rbc-toolbar-label {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 700;
  font-size: 18px;
  padding: 0px !important;
} */

.my-main-event-calendar .rbc-toolbar .rbc-toolbar-label {
  flex-grow: 0 !important;
}

.my-main-event-calendar .rbc-off-range-bg {
  background: none !important;
}

.my-main-event-calendar .rbc-today {
  background-color: #f9fafa !important;
  /* border-radius: 8px; */
}

.my-main-event-calendar .rbc-toolbar {
  padding: 0px 0px 20px 0px !important;
  margin-bottom: 0px !important;
}

.my-main-event-calendar .rbc-event {
  padding: 7px 10px !important;
  color: #000000 !important;

  background-color: #cce5f6 !important;
}

.my-main-event-calendar .custom-toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-main-event-calendar .custom-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 14px;
  cursor: pointer;
  font-size: 14px;
}

.my-main-event-calendar .custom-button:hover {
  background-color: #0056b3;
}

.my-main-event-calendar .rbc-toolbar {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.my-main-event-calendar .rbc-btn {
  background-color: #f9fafa !important;
  color: #000;
  border: none;
  padding: 7px 11px !important;
  height: 33px !important;
  width: 32px !important;
  cursor: pointer;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d1d1d1 !important;
}

.my-main-event-calendar .rbc-btn:hover {
  background-color: #ffffff !important;
}

.my-main-event-calendar .rbc-btn-nav-prev::before {
  /* content: '<'; */
  font-weight: 700;
}

.my-main-event-calendar .rbc-btn-nav-next::after {
  /* content: '>'; */
  font-weight: 700;
}

.my-main-event-calendar .rbc-btn-nav-prev {
  border-radius: 8px !important;
  /* border-right: none !important; */
}

.my-main-event-calendar .rbc-btn-nav-next {
  border-radius: 8px !important;
}

.my-main-event-calendar .new-event {
  background-color: #77c7a1 !important;
  color: #000000 !important;
}

.my-main-event-calendar .rbc-day-bg {
  padding: 10px !important;
}

.my-main-event-calendar .rbc-date-cell {
  padding-right: 6px !important;
  /* color: #000000 !important; */
  font-weight: 400 !important;
  font-family: Cerebri Sans !important;
}

/* .rbc-row {
    color: #000000 !important;
    font-weight: 400 !important;
    font-family: Cerebri Sans !important;

} */
.my-main-event-calendar .rbc-row-segment {
  padding: 0px 6px 4px 6px !important;
}

.my-main-event-calendar .rbc-event:focus {
  outline: none !important;
}

/* ////////////////////// Calendar CSS //////////////////////// */

.events-card-button button {
  border: 1px solid #d1d1d1;
  background-color: #fafafa;
  animation-duration: 1s;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  margin: auto;
}

.events-card-button button:hover {
  box-shadow: 0px 0px 5px 5px #d1d1d1;
  border: 1px solid #f0f0f0;
}

/* property open house  */

.optios-div {
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  background-color: #f9fafa;
  height: 100%;
}

.field-component {
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  background-color: #f9fafa;
}

.expand-list {
  border-left: 1px solid #d1d1d1;
}

.expand-list::before {
  content: "\2014";
  color: #d1d1d1;
}

.calendar-button {
  padding: 3px 15px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  background-color: #f9fafa;
  overflow: hidden;
}

/* /////////////////////////// Open house CSS ///////////////////// */

.open-house-event-calendar .rbc-btn-group button {
  padding: 30px;
}

.open-house-event-calendar .rbc-header {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border: none;
  font-family: Cerebri sans-medium;
  font-size: 16px;
  color: #888888;
}

.open-house-event-calendar .rbc-month-row {
  border: none;
}

.open-house-event-calendar .rbc-calendar {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 40px;
  border: 1px solid #d1d1d1;
}

.open-house-event-calendar .rbc-month-view {
  background-color: #ffffff;
  /* border-radius: 8px; */
  border: none;
}

.open-house-event-calendar .rbc-toolbar-label {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 700;
  font-size: 18px;
  padding: 0px !important;
}

.open-house-event-calendar .rbc-toolbar .rbc-toolbar-label {
  /* flex-grow: 0 !important; */
  font-family: Cerebri sans-bold;
  font-size: 14px;
}

.open-house-event-calendar .rbc-off-range-bg {
  background: none !important;
}

.open-house-event-calendar .rbc-today {
  background-color: #ffffff !important;
  /* border-radius: 8px; */
}

.open-house-event-calendar .rbc-toolbar {
  padding: 0px 0px 20px 0px !important;
  margin-bottom: 0px !important;
}

.open-house-event-calendar .rbc-event {
  padding: 3px 3px !important;
  color: #000000 !important;
  /* position: absolute; */
  /* top: 0px !important; */
  /* height: 49px; */
  /* width: 72px; */
  /* margin-left: 25px; */
  background-color: #619130 !important;
}

/* remove this if you want to show events in the previous and next months date which are showing in the present month */
.open-house-event-calendar .rbc-off-range {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.open-house-event-calendar .rbc-off-range::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 6px;
  border-radius: 5px;
  left: 0;
  bottom: -6px;
  background-color: white;
  color: #000000 !important;
}

.open-house-event-calendar .custom-toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.open-house-event-calendar .custom-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 14px;
  cursor: pointer;
  font-size: 14px;
}

.open-house-event-calendar .custom-button:hover {
  background-color: #0056b3;
}

.open-house-event-calendar .rbc-toolbar {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.open-house-event-calendar .rbc-btn {
  background-color: #f9fafa !important;
  color: #fff;
  border: none;
  padding: 7px 13px !important;
  cursor: pointer;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d1d1d1 !important;
}

.open-house-event-calendar .rbc-btn:hover {
  background-color: #ffffff !important;
}

.open-house-event-calendar .rbc-btn-nav-prev::before {
  /* content: '<'; */
  font-weight: 700;
}

.open-house-event-calendar .rbc-btn-nav-next::after {
  /* content: '>'; */
  font-weight: 700;
}

.open-house-event-calendar .rbc-btn-nav-prev {
  border-radius: 8px !important;
  /* border-right: none !important; */
}

.open-house-event-calendar .rbc-btn-nav-next {
  border-radius: 8px !important;
}

.open-house-event-calendar .new-event {
  background-color: #77c7a1 !important;
  color: #000000 !important;
}

.open-house-event-calendar .rbc-day-bg {
  padding: 10px !important;
  border-left: none !important;
}

.open-house-event-calendar .rbc-date-cell {
  /* text-align: center; */
  /* position: relative; */
  /* z-index: 99; */
  padding-right: 6px !important;
  display: flex;
  justify-content: center;
  font-weight: 400 !important;
  font-family: Cerebri Sans !important;
  /* margin-top: 13px !important; */
}

/* .rbc-row {
    color: #000000 !important;
    font-weight: 400 !important;
    font-family: Cerebri Sans !important;

} */
.open-house-event-calendar .rbc-row-segment {
  /* padding: 0px 6px 4px 6px !important; */
  outline: none !important;
}

.open-house-event-calendar .rbc-event:focus {
  outline: none !important;
}

/* circle css implementation */

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}

/* ////////////////////// open house CSS //////////////////////// */

.rbc-button-link .rbc-show-more {
  color: #000 !important;
}

/* Private view Card Container */

.private-view-card-container {
  border-radius: 8px;
  /* border: 1px solid var(--my-page-border-activated, #888); */
  background: var(--system-colors-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.square {
  width: 16px;
  height: 15.599px;
  flex-shrink: 0;
  border-radius: 4px;
}
