.main-device-approval {
  display: flex;
  justify-content: center;
  align-items: center;
  /* /* height: 100vh; */
}
.device-approval-container {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  background-color: white;
  width: fit-content;
  border-radius: 8px;
  min-width: 400px;
}
