.loginForm {
    background: white;
    border-radius: 12px;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    padding: 1.1rem 0.5rem;
}

.login-right-section {
    color: #111747;
}

.login-form {
    margin: 2rem 0 2rem 0;
}

.copyright-text {
    position: absolute;
    bottom: 15px;
    left: 20px;
}

.login-back {
    bottom: 0px;
    left: 0px;
}

.forgot-password-container {
    max-width: 900px;
}

.login-back {
    position: absolute;
}


.form-group label {
    font-family: Cerebri sans-light !important;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px !important;
    color: #000000;
    letter-spacing: 0.25px !important;
    transition: 0.1s;
    /* font-weight: 300 !important; */
    padding: 0px 4px 0px 4px !important;
  }
  
  /* the  [value=""] is used when value is having some values label should be show on top of input*/
  .form-group input:focus + label,
  .form-group input:not([value=""]) + label, 
  .form-group input:placeholder-shown + label {
    top: 0;
    font-size: 8px !important;
    letter-spacing: 0.25px !important;
    /* font-family: Cerebri sans-light !important; */
    color: #000000;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(247, 247, 247, 0) 50%
    );
    padding: 4px;
    /* font-weight: 300;  SV-20230516 - not needed as per figma */
  }
  
  .form-group input:focus {
    border: 1px solid #888;
  }
  
  .form-group input:hover {
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
  }
  
  .form-group input:disabled {
    background-color: #fff;
    cursor: not-allowed;
    color: #888888;
  }
  
  .form-group input:-webkit-autofill,
  .form-group input:-webkit-autofill:hover,
  .form-group input:-webkit-autofill:focus,
  .form-group input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000;
  }

.choose-profile-container .active{
  border-radius: 6px;
  border: 1px solid rgba(17, 23, 71, 0.10);
  box-shadow: 0px 4px 7px -2px #aaaaaa;
}

@media screen and (max-width: 880px) {
    .login-back {
        position: relative;
        margin-top: 0.5rem;
    }
}