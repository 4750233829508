.fnt-40 {
  font-size: 40px;
  line-height: 21px;
  margin-top: 75px;
  text-align: center;
  color: #111747;

}

/* Accordion  */

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #2A39AF !important;
  background: #F7F8FA !important;
  /* background-image:url('/src/assets/icons/needhelp_open_arrow.svg') !important;
  background-repeat: no-repeat !important; */
}

.nav-pills .nav-link {
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
  /* background-image:url('/src/assets/icons/needhelp_close_arrow.svg') !important;
  background-repeat: no-repeat !important; */
}

.accordion-button:not(.collapsed) {
  background-color: white !important;
  color: #2A39AF !important;
  box-shadow: none !important;
  background: #F7F8FA !important;
  font-family: 'Cerebri Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: none !important;
}

.accordion-button:not(.collapsed)::after {
  transform: var(--bs-accordion-btn-icon-transform);
}

/* right side accordion button */

.accordion-button:not(.collapsed)::after {
  background-image: url("/src/assets/images/opentab.png");
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:focus {
  z-index: 0;
  border-color: white;
  outline: 0;
  box-shadow: none !important;
}

.Faq-container .accordion-body {
  background: #f7f8fa;
}

/* Accordion end */
.bg-lite-orange {
  border-radius: 12px;
  background: #fff3e9;
}

.fwt-700 {
  font-family: 'Cerebri Sans';
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: #202020;
}

.fnt-12 {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #202020;

}

.fnt-18 {
  line-height: 22px;
  color: #202020;
}

/* message input box */
.form-message-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 300;
}

.form-message-input input::placeholder {
  opacity: 0;

}

.form-message-input input {
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 400;
  height: 135px;
  font-size: 10px !important;
  padding: 10px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  /* background: #F7F8FA; */

}

.dashed-border {
  background: #F7F8FA;
  border: 1.8px dashed rgba(17, 23, 71, 0.1);
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 400;
  height: 70px;
  font-size: 10px !important;
  padding: 10px;
  outline: none;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.input-bg-gray {
  background: #F7F8FA;
}

.form-message-input label {
  font-family: Cerebri sans !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000;
  /* letter-spacing: 1px; */
  transition: .1s;
  font-weight: 300 !important;
}

.form-message-input input:focus+label,
.form-message-input input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 10px !important;
  color: #888;
  background: #F9FAFB;
  padding: 4px;
}

.form-message-input input:focus {
  border: 1px solid #888;
}

.form-message-input input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.input-img {
  background: #ECEDF2;
  /* opacity: 0.25; */
  border-radius: 6px;
  width: 43px;
  height: 43px;
}

.img-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
}
.file-img-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px;
}


/* file upload */


.rectangle {

  background: #FFFFFF;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 4px 4px;

}

/* text-area */


.form-message-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 300;
}

.form-message-input textarea::placeholder {
  opacity: 0;

}

.form-message-input textarea {
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 400;
  height: 135px;
  /* font-size: 10px !important; */
  padding: 10px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  /* background: #F7F8FA; */

}

/* .dashed-border {
  background: #F7F8FA;
  border: 1px dashed rgba(17, 23, 71, 0.1);
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 400;
  height: 70px;
  font-size: 10px !important;
  padding: 10px;
  outline: none;
  box-sizing: border-box;
  border-radius: 8px !important;
} */

.input-bg-gray {
  background: #F7F8FA;
}

.form-message-input label {
  font-family: Cerebri sans !important;
  position: absolute;
  left: 10px;
  top: 14%;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000;
  /* letter-spacing: 1px; */
  transition: .1s;
  font-weight: 300 !important;
}

.form-message-input textarea:focus+label,
.form-message-input textarea:not(:placeholder-shown)+label {
  top: 0;
  font-size: 10px !important;
  color: #888;
  background: #F9FAFB;
  padding: 4px;
}

.form-message-input textarea:focus {
  border: 1px solid #888;
}

.form-message-input textarea:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.file-btn {
  background: #FFFFFF;
  border: 1px solid rgba(17, 23, 71, 0.1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  width: 86px;
  height: 30px;
}

.browes-border {
  background: #FFFFFF;
  border: 1px solid rgba(17, 23, 71, 0.1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
}

/* 
.fa-bolt{
  background: #ebebeb;
    opacity: 1.2;
    border-radius: 6px;
} */
.nav-pills .nav-link .arrow {
  color: #000;
  background-repeat: no-repeat;
  background-image: url(../assets/icons/tabclose.svg);
}

.nav-pills .nav-link.active .arroww {
  color: rebeccapurple;
  background-repeat: no-repeat;
  background-image: url(../assets/icons/tabopen.svg) !important;

}

.icon-bg-gray {
  background: #ebebeb;
}

.icon-bg-blue {
  background: #ced2eb;
  /* #2A39AF */
}

/* background-image:url(../assets/icons/leadoroductblue.svg) ; */
.nav-pills .nav-link .nice {
  color: red;
}



/* Faq component */

.Faq-container .bolt-wraper {
  background: #99999952;
  width: 25px;
  height: 25px;
}

.Faq-container .nav-pills .nav-link.active .bolt-wraper {
  background: #ced2eb;
}

.Faq-container .nav-pills .nav-link.active .bolt-wraper svg {
  color: #2A39AF !important;
}

.Faq-container .nav-pills .nav-link.active .faq-angle-icon {
  transform: rotate(270deg);
}

.validation-msg{
  font-size: 12px;
  margin-left: 10px;
  /* margin-top: 5px; */

}


/*------------------------------------ group text --------------------------------------*/

.form-input-group {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.form-input-group input::placeholder {
  opacity: 0;
}

.form-input-group input {
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  height: 40px;
  letter-spacing: 0.25px;
  font-size: 14px !important;
  padding: 12px !important;
  outline: none;
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: 8px;
}

.form-input-group label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  letter-spacing: 0.25px;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000;
  transition: 0.1s;
}

.form-input-group input:focus + label,
.form-input-group input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 8px !important;
  color: #888;
  background: rgb(255, 255, 255);
  letter-spacing: 0.25px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(247, 247, 247, 0) 50%
  );
  padding: 4px;
  font-family: Cerebri sans-medium !important;
}

.form-input-group input:focus {
  border: 1px solid #d1d1d1;
}

.form-input-group input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.form-input-group input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.form-input-group-gray {
  background-color: #f0f0f0;
  border: 1px solid #d1d1d1;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  right: 10px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Cerebri sans-semibold;
}

.form-input-group-gray:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}



/* need help page css */

.need-help-file-container .browse-btn {
  background-color: white;
  border: 1px solid #D1D1D1;
  border-radius: 5px;
}

.need-help-file-container .file-select {
  border: 1.8px dashed #D1D1D1 !important;
  border-radius: 8px;
}