.normalGroupTextStyle {
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    position: relative;
    width: 100%;
    padding: 0.5rem;
  }
  
  .normalGroupTextStyle:focus-visible {
    border: 1px solid #d1d1d1 !important;
    outline: none;
  }
  