.card-box {
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}

.guide-img::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  background: linear-gradient(180deg, rgba(17, 23, 71, 0) 0%, #111747 100%);
}

.line {
  position: absolute;
  width: 1px;
  top: 0;
  left: 47px;
  height: 100%;
  z-index: 0;
}

@media screen and (min-width: 768px) and (max-width: 776px) {
  .guide-manager {
    width: 231px !important;
  }
}
@media screen and (min-width: 425px) and (max-width: 480px) {
  .guide-manager {
    width: 400px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 424px) {
  .guide-manager {
    width: 400px !important;
  }
}
