.portal_btn {
  background-color: #adbe9e !important;
  border-radius: 6px;
  border: none !important;
  font-size: 0.7rem !important;
  letter-spacing: 0.07rem;
}

/* #root {
  height: 100vh;
} */

.form-group {
  /* padding: 0.5rem 0; */
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.form-group input:focus {
  outline: none;
}

.form-group input {
  /* border-radius: 6px;
  width: 100% !important;

  border: 1px solid rgba(17, 23, 71, 0.1);
  padding: 0.6rem 0.8rem; */
  width: 100% !important;
  color: rgba(17, 23, 71, 0.5);
  font-family: Cerebri sans-light !important;
  font-weight: 300;
  height: 44px;
  font-size: 16px !important;
  padding: 12px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 6px !important;
}

.form-group input::placeholder {
  font-size: 16px !important;
  font-family: Cerebri sans-light !important;
  color: rgba(17, 23, 71, 0.5);
}

.form-group input:-webkit-autofill,
.form-group input:-webkit-autofill:hover,
.form-group input:-webkit-autofill:focus,
.form-group input:-webkit-autofill:active {
  transition: background-color 10000s ease-in-out 0s;
  -webkit-text-fill-color: rgba(17, 23, 71, 0.5);
  font-family: Cerebri sans-light;
}

/* mui input text style */
.form-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.form-input input::placeholder {
  opacity: 0;
}

.form-input input {
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  /* font-weight: 300; */
  height: 40px;
  font-size: 14px !important;
  padding: 12px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 6px !important;
}

.form-input label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000000;
  letter-spacing: 0.25px !important;
  transition: 0.1s;
  /* font-weight: 300 !important; */
  padding: 0px 4px 0px 4px !important;
}

/* the  [value=""] is used when value is having some values label should be show on top of input*/
.form-input input:focus + label,
/* .form-input input:not([value=""]) + label,  */
.form-input input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 8px !important;
  letter-spacing: 0.25px !important;
  /* font-family: Cerebri sans-light !important; */
  color: #000000;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(247, 247, 247, 0) 50%
  );
  padding: 4px;
  /* font-weight: 300;  SV-20230516 - not needed as per figma */
}

.form-input input:focus {
  border: 1px solid #888;
}

.form-input input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.form-input input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.form-input input:-webkit-autofill,
.form-input input:-webkit-autofill:hover,
.form-input input:-webkit-autofill:focus,
.form-input input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

.css-qgckm3-indicatorSeparator {
  display: none !important;
}

.react-toastify {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #77c7a1;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #f1664b;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 20%;
  --toastify-toast-background: #f9fafa;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: Cerebri sans;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  /* Used only for colored theme */
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  /* Used when no type is provided */
  /* toast("**hello**") */
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  /* Used when no type is provided */
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 8px !important;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 0px 0px 0 rgba(0, 0, 0, 0.05) !important;
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  border: 1px solid #d1d1d1;
  background-color: var(--toastify-toast-background) !important;
  color: var(--toastify-text-color-light) !important;
}

.form-grey-input {
  position: relative !important;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.form-grey-input input::placeholder {
  opacity: 0;
}

.form-grey-input input {
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  /* font-weight: 300; */
  height: 40px;
  font-size: 16px !important;
  padding: 12px;
  color: rgba(17, 23, 71, 0.5);

  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 6px !important;
}

.form-grey-input label {
  /* font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  color: rgba(17, 23, 71, 0.50);

  transform: translateY(-50%);
  font-size: 16px !important;

  letter-spacing: 0.25px !important;
  transition: 0.1s;
  padding: 0px 4px 0px 4px !important; */
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000000;
  letter-spacing: 0.25px !important;
  transition: 0.1s;
  /* font-weight: 300 !important; */
  padding: 0px 4px 0px 4px !important;
}

.form-grey-input input:focus + label,
.form-grey-input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 8px !important;
  letter-spacing: 0.25px !important;
  font-family: Cerebri sans !important;
  color: rgba(17, 23, 71, 0.5);
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(247, 247, 247, 0) 50%
  );
  padding: 4px;
  /* font-weight: 300;  SV-20230516 - not needed as per figma */
}

.form-grey-input input:focus {
  border: 1px solid #888;
}

.form-grey-input input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.form-grey-input input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.form-grey-input input:-webkit-autofill,
.form-grey-input input:-webkit-autofill:hover,
.form-grey-input input:-webkit-autofill:focus,
.form-grey-input input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: rgba(17, 23, 71, 0.5);
}

.pb-13 {
  padding-bottom: 12px;
}

.form-input-grey {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.form-input-grey input::placeholder {
  opacity: 0;
}

.form-input-grey input {
  width: 100% !important;
  color: rgba(17, 23, 71, 0.5);

  font-family: Cerebri sans-light !important;
  /* font-weight: 300; */
  height: 40px;
  font-size: 16px !important;
  padding: 12px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 6px !important;
}

.form-input-grey label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px !important;
  color: rgba(17, 23, 71, 0.5);
  letter-spacing: 0.25px !important;
  transition: 0.1s;
  /* font-weight: 300 !important; */
  padding: 0px 4px 0px 4px !important;
}

.form-input-grey input:focus + label,
.form-input-grey input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 10px !important;
  letter-spacing: 0.25px !important;
  font-family: Cerebri sans !important;
  color: rgba(17, 23, 71, 0.5);
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(247, 247, 247, 0) 50%
  );
  padding: 4px;
  /* font-weight: 300;  SV-20230516 - not needed as per figma */
}

.form-input-grey input:focus {
  border: 1px solid #888;
}

.form-input-grey input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.form-input-grey input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.form-input-grey input:-webkit-autofill,
.form-input-grey input:-webkit-autofill:hover,
.form-input-grey input:-webkit-autofill:focus,
.form-input-grey input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

.cursor-pointer {
  cursor: pointer;
}

/* ////////// texta area mui style ///////////// */
textarea:focus ~ .floating-label,
textarea:not(:placeholder-shown) ~ .floating-label,
input:not(:focus):valid ~ .floating-label {
  top: 0px;
  left: 10px;
  transform: translateY(-50%);
  transition: 0.1s;
  padding: 4px;
  letter-spacing: 0.25px !important;
  font-family: Cerebri sans-medium !important;
  font-size: 8px;
  opacity: 1;
  background-color: white;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(247, 247, 247, 0) 50%
  );
  color: #999999;
}
.openhouse-des textarea {
  width: 100%;
  height: 120px;
  padding: 10px 10px 0 13px;
  color: #000;
  font-weight: 300;
  /* background-color: #f7f8fa; */
  letter-spacing: 0.25px !important;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  font-family: Cerebri sans-light !important;
  box-sizing: border-box;
}
.form-float textarea:focus {
  border: 1px solid #d1d1d1 !important;
  outline: 0;
  letter-spacing: 0.25px;
}
.form-float textarea:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}
.floating-label {
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transition: 0.2s ease all;
  transition: 0.1s;
  letter-spacing: 0.25px;
  color: #000;
  font-size: 14px;
  font-family: Cerebri sans-light !important;
}
.form-float {
  position: relative;
}
.openHouseinput input::placeholder {
  padding-left: 16px !important;
}

.faq-angle {
  transform: rotate(270deg);
}
/* ///////////////////// textarea mui styles/////////////////// */

.fnt-40 {
  font-size: 40px;
  line-height: 21px;
  margin-top: 75px;
  text-align: center;
  color: #111747;
}

/* Accordion  */

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #2a39af !important;
  background: #f7f8fa !important;
  /* background-image:url('/src/assets/icons/needhelp_open_arrow.svg') !important;
  background-repeat: no-repeat !important; */
}

.nav-pills .nav-link {
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
  /* background-image:url('/src/assets/icons/needhelp_close_arrow.svg') !important;
  background-repeat: no-repeat !important; */
}

.accordion-button:not(.collapsed) {
  background-color: white !important;
  color: #2a39af !important;
  box-shadow: none !important;
  background: #f7f8fa !important;
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: none !important;
}

.accordion-button:not(.collapsed)::after {
  transform: var(--bs-accordion-btn-icon-transform);
}

/* right side accordion button */

.accordion-button:not(.collapsed)::after {
  background-image: url("/src/assets/images/opentab.png");
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:focus {
  z-index: 0;
  border-color: white;
  outline: 0;
  box-shadow: none !important;
}

.Faq-container .accordion-body {
  background: #f7f8fa;
}

/* Accordion end */
.bg-lite-orange {
  border-radius: 12px;
  background: #fff3e9;
}

.fwt-700 {
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: #202020;
}

.fnt-12 {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #202020;
}

.fnt-18 {
  line-height: 22px;
  color: #202020;
}

/* message input box */
.form-message-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 300;
}

.form-message-input input::placeholder {
  opacity: 0;
}

.form-message-input input {
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 400;
  height: 135px;
  font-size: 10px !important;
  padding: 10px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  /* background: #F7F8FA; */
}

.dashed-border {
  background: #f7f8fa;
  border: 1.8px dashed rgba(17, 23, 71, 0.1);
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 400;
  height: 70px;
  font-size: 10px !important;
  padding: 10px;
  outline: none;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.input-bg-gray {
  background: #f7f8fa;
}

.form-message-input label {
  font-family: Cerebri sans !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000;
  /* letter-spacing: 1px; */
  transition: 0.1s;
  font-weight: 300 !important;
}

.form-message-input input:focus + label,
.form-message-input input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 10px !important;
  color: #888;
  background: #f9fafb;
  padding: 4px;
}

.form-message-input input:focus {
  border: 1px solid #888;
}

.form-message-input input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.input-img {
  background: #ecedf2;
  /* opacity: 0.25; */
  border-radius: 6px;
  width: 43px;
  height: 43px;
}

.img-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
}
.file-img-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px;
}

/* file upload */

.rectangle {
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 4px 4px;
}

/* text-area */

.form-message-input {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 300;
}

.form-message-input textarea::placeholder {
  opacity: 0;
}

.form-message-input textarea {
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 400;
  height: 135px;
  /* font-size: 10px !important; */
  padding: 10px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  /* background: #F7F8FA; */
}

/* .dashed-border {
  background: #F7F8FA;
  border: 1px dashed rgba(17, 23, 71, 0.1);
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 400;
  height: 70px;
  font-size: 10px !important;
  padding: 10px;
  outline: none;
  box-sizing: border-box;
  border-radius: 8px !important;
} */

.input-bg-gray {
  background: #f7f8fa;
}

.form-message-input label {
  font-family: Cerebri sans !important;
  position: absolute;
  left: 10px;
  top: 14%;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000;
  /* letter-spacing: 1px; */
  transition: 0.1s;
  font-weight: 300 !important;
}

.form-message-input textarea:focus + label,
.form-message-input textarea:not(:placeholder-shown) + label {
  top: 0;
  font-size: 10px !important;
  color: #888;
  background: #f9fafb;
  padding: 4px;
}

.form-message-input textarea:focus {
  border: 1px solid #888;
}

.form-message-input textarea:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.file-btn {
  background: #ffffff;
  border: 1px solid rgba(17, 23, 71, 0.1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  width: 86px;
  height: 30px;
}

.browes-border {
  background: #F2F4F6;
  border: 1px solid rgba(17, 23, 71, 0.1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
}

/* 
.fa-bolt{
  background: #ebebeb;
    opacity: 1.2;
    border-radius: 6px;
} */
.nav-pills .nav-link .arrow {
  color: #000;
  background-repeat: no-repeat;
  background-image: url(./assets/icons/tabopen.svg);
}

.nav-pills .nav-link.active .arroww {
  color: rebeccapurple;
  background-repeat: no-repeat;
  background-image: url(./assets/icons/tabopen.svg) !important;
}

.icon-bg-gray {
  background: #ebebeb;
}

.icon-bg-blue {
  background: #ced2eb;
  /* #2A39AF */
}

/* background-image:url(../assets/icons/leadoroductblue.svg) ; */
.nav-pills .nav-link .nice {
  color: red;
}

/* Faq component */

.Faq-container .bolt-wraper {
  background: #99999952;
  width: 25px;
  height: 25px;
}

.Faq-container .nav-pills .nav-link.active .bolt-wraper {
  background: #ced2eb;
}

.Faq-container .nav-pills .nav-link.active .bolt-wraper svg {
  color: #2a39af !important;
}

.Faq-container .nav-pills .nav-link.active .faq-angle-icon {
  transform: rotate(270deg);
}

.validation-msg {
  font-size: 12px;
  margin-left: 10px;
  /* margin-top: 5px; */
}

/*------------------------------------ group text --------------------------------------*/

.form-input-group {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
}

.form-input-group input::placeholder {
  opacity: 0;
}

.form-input-group input {
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  height: 40px;
  letter-spacing: 0.25px;
  font-size: 14px !important;
  padding: 12px !important;
  outline: none;
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: 8px;
}

.form-input-group label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  letter-spacing: 0.25px;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000;
  transition: 0.1s;
}

.form-input-group input:focus + label,
.form-input-group input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 8px !important;
  color: #888;
  background: rgb(255, 255, 255);
  letter-spacing: 0.25px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(247, 247, 247, 0) 50%
  );
  padding: 4px;
  font-family: Cerebri sans-medium !important;
}

.form-input-group input:focus {
  border: 1px solid #d1d1d1;
}

.form-input-group input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.form-input-group input:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.form-input-group-gray {
  background-color: #f0f0f0;
  border: 1px solid #d1d1d1;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  right: 10px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Cerebri sans-semibold;
}

.form-input-group-gray:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.bottom-line {
  border-bottom: 1px solid #f0f0f0;
}

/* COMMON CLASS FOR THE SCREENING */

.screening-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.document-property-table-btn {
  background: #d1d1d1;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  width: 27px;
  height: 27px;
}

.caret-color-transparent {
  caret-color: transparent; 
}


/* custom scroller */
::-webkit-scrollbar {
  height: 5px;
  border-radius: 100px;
  overflow: visible;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 100px;
}

::-webkit-scrollbar-track {
  background-clip: padding-box;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 100px;
  overflow: visible;
}

::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 100px;
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
}

::-webkit-scrollbar-track {
  background-clip: padding-box;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.form-check-input.is-valid {
  width: 17px;
  height: 17px;
  border: 1px solid #D1D1D1 !important;
}

.form-check-input.is-valid:checked {
  background-color: #91aa7b !important;
}