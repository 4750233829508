.upload-bg {
    background-color: #F2F4F6;
}

.screening-upload-img {
    background: #D1D1D1;
    border-radius: 6px;
    width: 43px;
    height: 43px;
    opacity: 0.9;
}

.screening-remove-uploaded-img {
    border-radius: 6px;
    width: 43px;
    height: 43px;
    background: #2b3c4959;
}

.screening-remove-bg {
    background: #DAD4CE;
    border: 1px dashed #888;
    border-radius: 6px;
}

.screening-remove-bg .browse-btn {
    background-color: white;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
}

.stepper-step-bg-color {
    color: #D0D1DE;
}

.stepper-step-active-color {
    color: #F3F4F8;
}

.screening-stepper-step-icon {
    width: 20px;
    height: 20px;
}

/* Screening label container */
.screening-form-label-container {
    background-color: #f7f8fa;
    /* border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 12px; */
}

.screening-form-label-container > div > img {
    object-fit: unset;
    min-height: 80px;
}

.screening-form-container{
    height: calc(100% - 60px);
}

.screening-form-container .form-input input::placeholder {
    opacity: 1;
  }

  
.screening-form-container input[type="date"] {
    cursor: default;
    overflow: hidden;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.screening-form-container input[type="date"]::-webkit-calendar-picker-indicator {
    margin-right: 0.5rem;
    width: 20px;
    height: 20px;
    /* border-width: thin; */
}

.screening-form-container textarea {
    resize: none;
    width: 100%;
    height: 100%;
    font-family: Cerebri sans-light;
    /* height: 40px; */
    font-size: 14px;
    padding: 12px;
    outline: none;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
}

.screening-footer  {
    position: fixed;
    width: 90%;
    bottom: 52px;
}
.screening-footer > div{
    height: 60px;
    padding: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.screening-save-and-finish-letter-modal,
.screening-need-help-modal-backdrop {
    z-index: 1055 !important;
}

.screening-overview-file {
    border: 1px solid #D1D1D1;
    border-radius: 6px;
    background-color: #E9EBF1;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
}

/* media queries */
@media screen and (min-width: 1016px){
    .screening-footer  {
        bottom: 33px;
        left: 49.99%;
        transform: translateX(-50%);
        /* padding-right: 1px; */
    }
}


.overview-fields {
    border-bottom: 2px solid #1117471A;
}

.congratulations-desc {
    max-width: 450px;
}

.congratulation-wave-guy {
    left: -236px;
    top: 150px;
}

.time-to-finish-screening-container {
    margin-left: 180px;
    left: "50%",
}

.screening-render-component {
    overflow: hidden;
}

/* .screening-slide-exit {
    transform: translate(-100%, 0%);
    }
.screening-slide-exit-active {
    transform: translate(-200%, 0%);
    transition: transform 500ms ease-in-out;
} */

.screening-slide-animation {
    animation: screening-RightToLeft 0.7s ease-in-out;
}

@keyframes screening-RightToLeft {
    0% {
        transform: translate(100%, 0%);
    }
    100% {
        transform: translate(0%, 0%);
    }
  }

.screening-accordion-icons {
  border: 1px solid #D1D1D1 !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: white !important;
  color: black !important;
}

.screening-render-component .error .css-1h74jmt-control {
    border: 1px solid #E33716 !important;
}

.screening-render-component .file-select.error {
    border: 1.8px dashed #E33716 !important;
}

.screening-phone-number-container.error {
    border: 1px solid #E33716;
}

.screening-render-component .file-select {
    border: 1.8px dashed #D1D1D1 !important;
    border-radius: 8px;
}

.screening-render-component .file-select .browse-btn{
    background-color: white;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
}

.screening-render-component .normal-group-text.error input,
.screening-render-component .error .form-input-group-gray {
    border: 1px solid #E33716 !important;
}

.screening-render-component .form-input.error input {
    border: 1px solid #E33716 !important;
}
  
.screening-render-component .form-input.error textarea {
border: 1px solid #E33716 !important;
}

.screening-form-container .need-help {
    margin-bottom: 1rem;
}

.screening-form-container .commented-container {
    border-radius: 6px;
    border: 1px solid #D1D1D1;
    background: #F9FAFA;
    height: 100px;
    overflow: auto;
}

.screening-form-container .commented-container .comment-delete-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 20px;
    bottom: 10px;
    border-radius: 4px;
    border: 1px solid #D1D1D1;
    background: #FFF;
    width: 30px;
    height: 30px;
}

.screening-phone-number-container {
    border: 1px solid #D1D1D1;
    border-radius: 8px;
}

.form-input.information-form-number input {
    border: none !important;
    border-left: 1px solid #D1D1D1 !important;
    border-radius: unset !important;
}

.screening-loader-container .wavy {
    position: relative;
  }
  .screening-loader-container .wavy span {
    position: relative;
    display: inline-block;
    color: #fff;
    margin: 0 5px;
    text-transform: uppercase;
    animation: animate 1.2s ease-in-out infinite;
    animation-delay: calc(0.1s * var(--i));
  }

.screening-form-container .form-input input::placeholder {
    opacity: 1;
    color: #999999 !important;
    font-size: 14px;
}

.screening-form-container .screening-form-textarea-border {
    /* border: 1px solid #d1d1d1; */
    background-color: #fff;
    border-radius: 6px;
}

.screening-form-container .screening-msg-container .form-input input:focus + label,
.screening-form-container .screening-msg-container .form-input input:placeholder-shown + label{
    top: 0;
    background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(247, 247, 247, 0) 50%
    );
    color: #000;
    font-size: 10px !important;
    padding: 4px;
}

.screening-form-container .form-message-input textarea::placeholder {
    opacity: 1;
    color: #999999 !important;
    font-size: 14px;
}

.screening-form-container .screening-form-textarea-border div textarea{
    border: none !important;
}

.screening-form-container .screening-form-textarea-border div textarea:focus,
.screening-form-container .screening-form-textarea-border div textarea:hover {
    border: none !important;
    outline: none !important;
    box-shadow: unset !important;
}

.screening-form-container .form-message-input textarea:focus + label,
.screening-form-container .form-message-input textarea:placeholder-shown + label{
    top: 0;
    background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(247, 247, 247, 0) 50%
    ) !important;
    color: #000;
    font-size: 10px !important;
    padding: 4px;
}


.screening-form-container .form-message-input textarea:focus+label, .screening-form-container .form-message-input textarea:not(:placeholder-shown)+label {
    top: 0;
    font-size: 10px !important;
    color: #888;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 50%,
        rgba(247, 247, 247, 0) 50%,
        ) !important;
    padding: 4px;
}
#chatbox2textarea {
    background-color: #fff;
    border-radius: 6px;
}
#chatbox2textarea textarea {
    border: none !important;
}
#chatbox2textarea textarea:hover {
    box-shadow: none;
}

.screening-mobile-wizard-header {
    height: 40px;
    background-color: #111747;
}

.screening-mobile-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.screening-mobile-footer .container {
    background-color: #0C1356;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}

.screening-mobile-footer-progressbar {
    height: 10px;
    background-color: #54A64D;
    border-radius: 10px;
}

.screening-mobile-form-container {
    z-index: 1;
}

.screening-mobile-form-container::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 36px;
    background: #f7f8fa;
    top: -16px;
    z-index: -1;

}

.screening-mobile-rounded {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}

.screening-information-phone-number input {
    border-left: 1px solid #D1D1D1 !important;
}

.screening-information-phone-number input:focus {
    outline: none;
}

.customer-list-prescreening {
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    height: 150px;
}

  @keyframes animate {
    0% {
      transform: translateY(0px);
      background-color: #686b89;
    }
    20% {
      transform: translateY(-30px);
      background-color: #131947;
    }
    40%,
    100% {
      transform: translateY(0px);
      background-color: #bdc0cd;
    }
  }

  .screening-loader-container .wavy span {
    width: 23px;
    height: 23px;
    background-color: #686b89;
    border-radius: 100%;
  }  


/* media queries */
@media screen and (min-width: 1016px){
    .screening-form-container .need-help {
        position: fixed;
        margin-bottom: 1rem;
        bottom: 100px;
        right: 75px;
        z-index: 10;
    }
}

/* media queries */
@media screen and (min-width: 1386px){
    .screening-form-container .need-help {
        right: 115px;
    }
}

/* media queries */
@media screen and (max-width: 1200px){
    .screening-form-container .MuiBox-root.css-u28xu2::before{
        left: 34px;
    }
}

@media screen and (max-width: 992px){
    .screening-form-container .MuiBox-root.css-u28xu2::before{
        left: 30px;
    }
    .screening-form-label-container > div > img {
        object-fit: cover;
    }
    .time-to-finish-screening-container {
        margin-left: unset;
        left: unset,
    }
}

.screeningFAQicons .accordion-button::after {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:%232a39af}</style><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>');
}

.screeningFAQicons .accordion-button:not(.collapsed)::after{
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:%232a39af}</style><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>');
}

.screeningFAQicons .accordion-item {
    border: 1px solid #00000080 !important;
}

.modal-dialog.screening-modal {
    width: 90% !important;
    height: 90% !important;
    border-radius: 12px;
    position: relative;
}

/* media queries */
@media screen and (min-width: 1386px){
    
    .screening-flag-image {
        position: absolute;
        bottom: -41px;
    }
    .screening-flag-image img {
       width: 500px;
    }
}
