.form-inpu-field-session-expire {
  position: relative;
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 300;
  color: #888
}

.form-inpu-field-session-expire input::placeholder {
  opacity: 0;
}

.form-inpu-field-session-expire input {
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 400;
  height: 40px;
  /* font-size: 10px !important; */
  padding: 10px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 6px 6px 6px 6px !important;

}

.form-inpu-field-session-expire label {
  font-family: Cerebri sans !important;
  position: absolute;
  left: 9px;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
  /* letter-spacing: 1px; */
  transition: .1s;
  font-weight: 300 !important;
}

.form-inpu-field-session-expire input:focus+label,
.form-inpu-field-session-expire input:not(:placeholder-shown)+label {
  top: 0;
  color: #888;
  background: white;
  padding: 4px;
  font-size: 10px;
}

.form-inpu-field-session-expire input:focus {
  border: 1px solid #888;
}

.form-inpu-field-session-expire input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.form-inpu-field-session-expire:focus-within label,
.form-inpu-field-session-expire:focus-within :not(:placeholder-shown) label {
  top: 0;
  color: #888;
  background: #F9FAFB;
  padding: 4px;
}

.form-session {
  width: 43% !important;
}

/* select  */

.form-inpu-field-session-expire {
  position: relative;
  width: 100%;
  font-family: Cerebri sans !important;
  font-weight: 300;
}

.form-inpu-field-session-expire select::placeholder {
  opacity: 0;
}

.form-inpu-field-session-expire select {
  width: 100% !important;
  font-family: Cerebri sans !important;
  font-weight: 400;
  height: 40px;
  /* font-size: 10px !important; */
  padding: 10px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 6px 6px 6px 6px !important;

}

.form-inpu-field-session-expire label {
  font-family: Cerebri sans !important;
  position: absolute;
  left: 9px;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
  /* letter-spacing: 1px; */
  transition: .1s;
  font-weight: 300 !important;
}

.form-inpu-field-session-expire select:focus+label,
.form-inpu-field-session-expire select:not(:placeholder-shown)+label {
  top: 0;
  color: #888;
  background: white;
  padding: 4px;
  font-size: 10px;
}

.form-inpu-field-session-expire select:focus {
  border: 1px solid #888;
}

.form-inpu-field-session-expire select:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.form-inpu-field-session-expire:focus-within label,
.form-inpu-field-session-expire:focus-within :not(:placeholder-shown) label {
  top: 0;
  color: #888;
  background: #F9FAFB;
  padding: 4px;
}

.country-code {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}